var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        scrollable: "",
        width: _vm.dialogWidth,
        overlay: false,
        transition: "dialog-transition",
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on }) {
              return [_c("div", _vm._g({}, on), [_vm._t("default")], 2)]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "text-h5 grey lighten-2" }, [
            _vm._v("Usage statistics"),
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "4" } }, [
                    _c(
                      "div",
                      { staticClass: "text-center py-5" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { text: "" },
                            on: {
                              click: function ($event) {
                                return _vm.gotoFiles(
                                  _vm.folderTypesLabels.WORKSPACE_FILES
                                )
                              },
                            },
                          },
                          [
                            _vm._v(" Total usage "),
                            _c(
                              "v-icon",
                              { staticClass: "ml-1", attrs: { small: "" } },
                              [_vm._v("mdi-open-in-new")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "px-4" },
                      [
                        _vm.fetchingSnapshotQuota
                          ? _c("dashboard-loader")
                          : [
                              !_vm.isEmpty(_vm.chartData) &&
                              !_vm.$isError(_vm.chartData)
                                ? [
                                    _c("doughnut-chart", {
                                      attrs: {
                                        chartData: _vm.chartData,
                                        options: _vm.chartOptions,
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              _vm.$isError(_vm.chartData)
                                ? _c("dashboard-error")
                                : _vm.isEmpty(_vm.chartData)
                                ? _c("dashboard-empty")
                                : _vm._e(),
                            ],
                      ],
                      2
                    ),
                  ]),
                  _c("v-col", { attrs: { cols: "4" } }, [
                    _c(
                      "div",
                      { staticClass: "text-center py-5" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { text: "" },
                            on: {
                              click: function ($event) {
                                return _vm.gotoFiles(
                                  _vm.folderTypesLabels.PERSONAL_FILES
                                )
                              },
                            },
                          },
                          [
                            _vm._v(" Personal usage "),
                            _c(
                              "v-icon",
                              { staticClass: "ml-1", attrs: { small: "" } },
                              [_vm._v("mdi-open-in-new")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "px-4" },
                      [
                        _vm.fetchingSnapshotQuota
                          ? _c("dashboard-loader")
                          : [
                              !_vm.isEmpty(_vm.personalChartData) &&
                              !_vm.$isError(_vm.personalChartData)
                                ? [
                                    _c("doughnut-chart", {
                                      attrs: {
                                        chartData: _vm.personalChartData,
                                        options: _vm.chartOptions,
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              _vm.$isError(_vm.personalChartData)
                                ? _c("dashboard-error")
                                : _vm.isEmpty(_vm.personalChartData)
                                ? _c("dashboard-empty")
                                : _vm._e(),
                            ],
                      ],
                      2
                    ),
                  ]),
                  _c("v-col", { attrs: { cols: "4" } }, [
                    _c(
                      "div",
                      { staticClass: "text-center py-5" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { text: "" },
                            on: {
                              click: function ($event) {
                                return _vm.gotoApps()
                              },
                            },
                          },
                          [
                            _vm._v(" Application usage "),
                            _c(
                              "v-icon",
                              { staticClass: "ml-1", attrs: { small: "" } },
                              [_vm._v("mdi-open-in-new")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "px-4" },
                      [
                        _vm.fetchingSnapshotQuota
                          ? _c("dashboard-loader")
                          : [
                              !_vm.isEmpty(_vm.appChartData) &&
                              !_vm.$isError(_vm.appChartData)
                                ? [
                                    _c("doughnut-chart", {
                                      attrs: {
                                        chartData: _vm.appChartData,
                                        options: _vm.chartOptions,
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              _vm.$isError(_vm.appChartData)
                                ? _c("dashboard-error")
                                : _vm.isEmpty(_vm.appChartData)
                                ? _c("dashboard-empty")
                                : _vm._e(),
                            ],
                      ],
                      2
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "secondary", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }